<template>
  <div>
    <section class="hero is-hero-bar is-main-hero">
      <div class="hero-body">
        <div class="level">
          <div class="level-left">
            <div class="level-item is-hero-content-item">
              <div style="padding: 0 2em">
                <h1 class="title is-spaced">実施確認</h1>
                <h3 class="subtitle" v-if="targetDate">
                  <b>{{ targetDate.format('YYYY/MM/DD (dd)') }}</b>
                </h3>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <div class="is-relative" v-if="isLoading" style="min-height: 100px">
      <b-loading :is-full-page="false" :active="isLoading" />
    </div>
    <section class="section is-main-section" v-else>

      <!-- エリア選択 -->
      <card-component title="店舗選択" v-if="!area">
        <div class="columns is-multiline is-mobile is-centered">
          <div class="column is-half" v-for="area in AREAS" :key="area.value">
            <b-button
              size="is-menium"
              type="is-success"
              outlined
              expanded
              @click.prevent="areaAlt = area"
            >
              {{ area.label }}
            </b-button>
          </div>
        </div>
      </card-component>

      <!-- レッスン一覧 -->
      <div v-if="area">

        <b-field label="" class="has-text-right">
          <b-switch v-model="showAll" size="is-medium" leftLabel
            >OFFスタッフ表示</b-switch
          >
        </b-field>

        <b-button
          class="mb-4"
          icon-left="chevron-double-left"
          outlined
          expanded
          size="is-medium"
          @click.prevent="willBack"
        >
          <small>店舗選択に戻る</small>
        </b-button>
        <card-component
          :title="`@${area.label}  ${filterEvents.length}件`"
          :has-button-slot="true"
        >
          <refresh-button
            slot="button"
            label="更新"
            :isLoading="isLoading"
            @button-click="willFetch"
          />
          <CheckinEventMedia
            v-for="tEvent in filterEvents"
            :key="tEvent.id"
            :tEvent="tEvent"
            @willCheckin="willCheckin"
          />
          <section
            class="section has-text-centered"
            v-if="filterEvents.length === 0"
          >
            <p>
              <b-icon icon="calendar-minus" size="is-large" />
            </p>
            <p>レッスンがありません</p>
          </section>
        </card-component>

        <b-button
          v-if="filterEvents.length > 5"
          class="mb-4"
          icon-left="arrow-collapse-up"
          outlined
          expanded
          size="is-medium"
          @click.prevent="scrollToTop"
        >
          <small>上へ戻る</small>
        </b-button>
      </div>
    </section>
  </div>
</template>

<script>
import BaseMixin from '@/baseMixin'
import { Enums } from '@/types/enum'
import { mapActions, mapState } from 'vuex'
import CheckinFormVue from '@/components/Checkin/CheckinForm.vue'
import CheckinEventMedia from '@/components/Checkin/CheckinEventMedia.vue'
import CardComponent from '@/components/CardComponent.vue'
import RefreshButton from '@/components/RefreshButton.vue'
import dayjs from 'dayjs'
export default {
  mixins: [BaseMixin],
  props: {},
  components: { CheckinEventMedia, CardComponent, RefreshButton },
  data () {
    return {
      area: null,
      showAll: false,
      intervalId: null,
    }
  },
  computed: {
    titleStack () {
      if (!this.area) {
        return ['実施確認']
      }
      return [this.area.label]
    },
    AREAS: () => Enums.Area.list,
    ...mapState('storeCheckin', ['targetDate', 'tEvents']),
    areaAlt: {
      get () {
        return this.area
      },
      set (area) {
        this.isLoading = true
        setTimeout(() => {
          this.isLoading = false
          this.area = area
        }, 300)
      }
    },
    filterEvents () {
      const { area, showAll } = this
      return this.tEvents.filter(tEvent => {
        if (area && area !== tEvent.area) {
          return false
        }
        if (!showAll && !tEvent.checkable && !tEvent.hasCheckin) {
          return false
        }
        return true
      })
    },
  },
  methods: {
    ...mapActions('storeCheckin', {
      forgeCheckinStates: 'forgeStates',
      forgeCheckinDatas: 'forgeDatas',
    }),
    willBack () {
      this.areaAlt = null
    },
    willFetch () {
      this.isLoading = true
      setTimeout(() => {
        this.forgeCheckinDatas(null).then(() => {
          this.isLoading = false
        }).catch(this.handleError)
      }, 300)
    },
    willCheckin (tEvent) {
      this.$buefy.modal.open({
        parent: this,
        component: CheckinFormVue,
        fullScreen: true,
        hasModalCard: true,
        canCancel: false,
        trapFocus: true,
        props: { tEvent },
        events: {
          saved: () => {
            console.log('saved')
            this.alertSuccess('実施確認を登録しました')
          },
        }
      })
    },
    scrollToTop () {
      window.scrollTo({ top: 0, behavior: 'smooth' })
    }
  },
  created () {
    this.isLoading = true
    this.forgeCheckinStates(true).catch(this.handleError)
  },
  mounted () {
    this.forgeCheckinDatas(null).then(() => {
      this.isLoading = false

      this.intervalId = setInterval(() => {
        const nowDay = dayjs()
        if (!this.targetDate || nowDay.isSame(this.targetDate, 'day')) {
          return
        }
        this.isLoading = true
        this.forgeCheckinDatas(nowDay).then(() => {
          this.isLoading = false
        }).catch(this.handleError)
      }, 1000 * 60 * 15)
    }).catch(this.handleError)
  },
  beforeDestroy () {
    if (this.intervalId) {
      clearInterval(this.intervalId)
    }
  }
}
</script>

<style>
</style>