<template>
  <ValidationObserver ref="observer" v-slot="{ handleSubmit }" slim>
    <div class="modal-card" style="width: auto">
      <header class="modal-card-head">
        <p class="modal-card-title">
          {{ mProgram.name }}
        </p>
      </header>
      <section class="modal-card-body">
        <form autocomplete="off" style="font-size: 1.2em">
          <b-field label="実施日">
            <p v-if="tEvent.startDate && tEvent.endDate">
              {{ tEvent.startDate.format('YYYY/MM/DD HH:mm') }} ~
              {{ tEvent.endDate.format('HH:mm') }}
              <br />@{{ tEvent.facility.label }}
            </p></b-field
          >
          <b-field label="担当" v-if="mInstructor">
            {{ mInstructor.name }}
          </b-field>
          <b-field
            label="代行"
            v-if="mInstructor"
            message="担当以外の方が実施された場合ONにしてください"
          >
            <b-switch size="is-medium" v-model="checkinForm.proxyFlag">{{
              checkinForm.proxyFlag ? 'ON' : 'OFF'
            }}</b-switch>
          </b-field>

          <!-- 店舗権限で代行のみ管理番号をきく -->
          <b-field
            label="代行管理番号"
            message="代行された方の管理番号を数字4桁で入力してください"
            v-if="checkinForm.proxyFlag"
          >
            <ValidationField rules="required|min:4">
              <b-input
                type="text"
                icon="account"
                size="is-large"
                placeholder="0123"
                v-model="checkinForm.proxyCode"
              >
              </b-input>
            </ValidationField>
          </b-field>

          <!-- 店舗権限のみパスワードをきく -->
          <b-field
            label="パスワード"
            :message="
              checkinForm.proxyFlag
                ? '代行された方のパスワードを入力してください'
                : `担当インストラクターのパスワードを入力してください`
            "
          >
            <ValidationField rules="required">
              <b-input
                type="text"
                icon="key"
                size="is-large"
                v-model="checkinForm.password"
                class="password-binput"
              >
              </b-input>
            </ValidationField>
          </b-field>
          <b-field label="連絡事項">
            <b-input
              type="textarea"
              v-model="checkinForm.note"
              maxlength="100"
              :rows="2"
            />
          </b-field>
        </form>
      </section>
      <footer class="modal-card-foot">
        <b-button label="閉じる" :loading="isLoading" @click="$emit('close')" />
        <b-button
          type="is-primary"
          @click.prevent="handleSubmit(willSave)"
          :loading="isLoading"
          expanded
          >実施確認を登録する</b-button
        >
      </footer>
    </div>
  </ValidationObserver>
</template>

<script>
import BaseMixin from '@/baseMixin'
import { CheckinForm } from '@/types/typeCheckin'
import { mapActions, mapGetters } from 'vuex'
import ValidationField from '../Shared/ValidationField.vue'
import { TEvent } from '@/types/typeEvent'
import { InValidServiceException } from '@/config/exception'
export default {
  mixins: [BaseMixin],
  props: {
    tEvent: TEvent,
  },
  components: { ValidationField },
  data () {
    return {
      checkinForm: new CheckinForm(this.tEvent)
    }
  },
  computed: {
    ...mapGetters('storeCheckin', ['findMProgram', 'findMInstructor']),
    mProgram () {
      return this.findMProgram(this.tEvent.programId)
    },
    mInstructor () {
      return this.findMInstructor(this.tEvent.instructorId)
    },
  },
  methods: {
    ...mapActions('storeCheckin', ['createCheckin']),
    willSave () {
      console.log('willSave')
      this.isLoading = true
      setTimeout(() => {
        this.createCheckin(this.checkinForm).then(() => {
          this.$emit('saved')
          this.$emit('close')
          this.isLoading = false
        }).catch((error) => {
          this.isLoading = false
          if (error instanceof InValidServiceException) {
            this.$buefy.toast.open({
              message: error.message || '入力内容をご確認ください',
              position: 'is-top',
              type: 'is-danger',
              duration: 1000
            })
            return
          }
          this.handleError(error)
        })
      }, 500)
    },
  }
}
</script>
