<template>
  <article class="media">
    <figure class="media-left has-text-centered">
      <b-icon
        icon="bookmark-check"
        size="is-large"
        type="is-success"
        v-if="tEvent.hasCheckin"
      />
      <b-icon icon="bookmark-outline" size="is-large" v-else />
      <br />
      <small v-if="tEvent.hasCheckin" class="pt-2">{{
        tEvent.checkinAtLabel
      }}</small>
    </figure>
    <div class="media-content">
      <div class="content">
        <p>
          <small v-if="tEvent.startDate"
            ><b-icon icon="clock" size="is-small" />{{
              tEvent.startDate.format('HH:mm')
            }}</small
          >
          <small v-if="tEvent.endDate">
            ~ {{ tEvent.endDate.format('HH:mm') }}</small
          >
          <small v-if="tEvent.facility" class="pl-2">
            @{{ tEvent.facility.label }}</small
          >
          <br />
          <strong class="is-size-5" v-if="mProgram">{{ mProgram.name }}</strong>
          <br />
          <strong class="is-size-5" v-if="mInstructor">{{ mInstructor.name }}</strong>
          <b-taglist attached v-if="mProxy" class="mt-3">
            <b-tag type="is-warning">代行</b-tag>
            <b-tag type="is-light">{{ mProxy.name }}</b-tag>
          </b-taglist>
        </p>
        <p v-if="note">
          {{ note }}
        </p>
      </div>
      <b-button
        v-if="!tCheckin"
        label="実施確認"
        type="is-info"
        icon-right="check"
        outlined
        @click.prevent="$emit('willCheckin', tEvent)"
      />
    </div>
  </article>
</template>

<script>
import { TEvent } from '@/types/typeEvent'
import { mapGetters } from 'vuex'
export default {
  props: {
    tEvent: TEvent,
  },
  computed: {
    ...mapGetters('storeCheckin', ['findMProgram', 'findMInstructor']),
    mProgram () {
      return this.findMProgram(this.tEvent.programId)
    },
    mInstructor () {
      return this.findMInstructor(this.tEvent.instructorId)
    },
    tCheckin () {
      return this.tEvent.tCheckin || null
    },
    mProxy () {
      return this.tEvent.isProxy ? this.findMInstructor(this.tCheckin.proxyId) : null
    },
    note () {
      return this.tCheckin ? this.tCheckin.note : null
    },
  }
}
</script>

<style>
</style>